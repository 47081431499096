import PropTypes from "prop-types"
import React from "react"
import Container from "react-bootstrap/Container"

const Footer = ({siteTitle}) => (
    <footer>
        <Container className="my-5 text-center">
            <p className="small text-secondary"><sup>*</sup>All natural means our meats are minimally processed (except for caramel color on roast beef) and contain no artificial ingredients.</p>
            <p className="small text-secondary"><sup>&dagger;</sup>Local time at the store location.</p>

            <p className="small mt-5">&copy; {new Date().getFullYear()} Jimmy John's of East Bay</p>
        </Container>
    </footer>
);

Footer.propTypes = {
    siteTitle: PropTypes.string,
};

Footer.defaultProps = {
    siteTitle: ``,
};

export default Footer
