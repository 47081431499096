import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import {Link} from "gatsby"
import jjLogoBlack from "../images/logos/jj-logo-black.png"

const Header = ({siteTitle}) => (
    <header>
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand>
                    <Link className="navbar-brand" to="/">
                        <small>JJ East Bay</small>
                    </Link>
                </Navbar.Brand>
                <div className="navbar-brand-logo d-lg-none">
                    <Link to="/"><img src={jjLogoBlack} width="75" height="75" alt="Home"/></Link>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto text-center my-4">
                        <Link className="nav-link py-3 text-uppercase" to="/catering">Catering</Link>
                        <Link className="nav-link py-3 text-uppercase" to="/daily-deals">Daily Deals</Link>
                        <Link className="nav-link py-3 text-uppercase" to="/menu">Menu</Link>
                        <Link className="nav-link py-3 text-uppercase" to="/locations">Locations</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header
